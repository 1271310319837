/* desktop */
body {
	margin: 0px;
	background-color: white;
	font-size: 17px; 
	line-height: 150%;
	height: 100%;
}
html {
	height: 100%;
}

.pageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
}

h1 {
	font-weight: 600;
	font-family: 
		"Mulish",
		"sans-serif";
}

h1 {
	line-height: 45px;
}

h2 {
	font-weight: 600;
	font-family: 
		"Mulish",
		"sans-serif";
	margin: 0px;
	line-height:  1.6;
}

a {
	font-weight: 500;
	font-family: 
		"Mulish",
		"sans-serif";
	margin: 0px;
	line-height:  1.6;
}

span, div {
	font-weight: 500;
	font-family: 
		"Mulish",
		"sans-serif";
}

p {
	font-weight: 400;
	font-family:
		"Mulish",
		"sans-serif";
	margin-bottom: 15px;
/*	font-size: 100%;*/
}

td {
	font-family: 
		"Mulish",
		"sans-serif";	
	font-size: 75%;
	overflow: hidden;
	white-space: nowrap;
	vertical-align: top;
    text-overflow: ellipsis;
}

td:hover {
	vertical-align: top;
	white-space: normal;
	overflow: visible;
}	

table {
    table-layout:fixed;
  	width:100%;
}

hr {
	border:  0px;
	margin: 0px;
	height:  1px;
	background-color:  black;
}

.imageloader img{
	margin-bottom: -6px;
}

.lty-playbtn {
	border: none;
	cursor: pointer;
}

.yt-lite:hover > .lty-playbtn {
 background-color: #9d2121 !important;
}